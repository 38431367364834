import React, { Component } from 'react';
import landerRightBG from '../static/landerRightImg.svg';
import landerLeftLogo from '../static/landerLeftLogo.png';

const withApiCallRedirect = (WrappedComponent) => {
  class ApiCallRedirectComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        authenticationError: false,
      };
    }
    
    handleFormSubmit = (event) => {
      event.preventDefault();

      const username = event.target.elements.username.value;
      const password = event.target.elements.password.value;

      const userCredentials = [
        { username: 'mohit', password: 'mohit' },
      ];

      const matchedUser = userCredentials.find(
        (user) => user.username === username && user.password === password
      );

      if (matchedUser) {
        // Authentication successful
        sessionStorage.setItem('authenticated', 'true');
        window.location.href = '/';
      } else {
        // Authentication failed
        this.setState({ authenticationError: true }); // Set the error state
      }
    

      // // Make the API call to authenticate the user
      // fetch('https://myaimate-backend.onrender.com/api/v1/auth/login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ username, password }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
          
      //     if (data.status === 200) {
      //       sessionStorage.setItem('loginToken', JSON.stringify(data.data.token));
      //       sessionStorage.setItem('authenticated', 'true');
      //       // If the API response is 'allowed', trigger a programmatic redirection
      //       window.location.href = '/'; // Replace with the actual path
      //     }
      //     else {
      //       this.setState({ authenticationError: true });
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('API call failed', error);
      //     this.setState({ authenticationError: true });
      //   });
    };

    componentDidMount() {
      // Check if the stored API response exists
      const storedApiResponse = JSON.parse(sessionStorage.getItem('authenticated'));
  
      if (storedApiResponse) {
        // If it exists, set a timeout to clear it after 30 seconds
        setTimeout(() => {
          sessionStorage.removeItem('authenticated');
        }, 10800000); // 3hours in milliseconds
      }
    }

    render() {
      const { authenticationError } = this.state;
      // Get the stored API response from session storage
      // const storedApiResponse = JSON.parse(sessionStorage.getItem('apiResponse'));
      const storedApiResponse = sessionStorage.getItem("authenticated");
      if (!storedApiResponse) {
        // If the stored API response is empty, render the login form
        return (
          <div style={{ position: 'absolute', display: 'flex', width: '100%', marginTop: 'auto' }}>
            <div style={{ textAlign: 'center', width: '55vw', margin: 'auto', padding: '0 5%' }}>
              <img style={{ maxWidth: '45%' }} src={landerLeftLogo} alt="Aimate Logo" />
              <h1 style={{ fontSize: '3em' }}>Login into your Account</h1>
              <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} onSubmit={this.handleFormSubmit}>
                <input style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none'
                }} type="text" name="username" placeholder="Username" />
                <input style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none'
                }} type="password" name="password" placeholder="Password" />
                <button style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none',
                  background: '#49a4ff',
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '16px',
                  cursor: 'pointer',
                }} type="submit">Submit</button>
              </form>
              {authenticationError && <p style={{ color: 'red' }}>Authentication failed. Please check your username and password.</p>}
            </div>
            <div style={{
              margin: 'auto',
              width: '45vw'
            }}>
              <img style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} src={landerRightBG} alt="Aimate Logo" />
            </div>
          </div>
        );
      } else {
        // Render the wrapped component
        return <WrappedComponent {...this.props} />;
      }
    }
  }

  return ApiCallRedirectComponent;
};

export default withApiCallRedirect;
